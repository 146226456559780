import React from "react";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { Type, GetAuthorsQuery } from "@/graphql/gql/graphql";
import { getArticlesFromAuthorsQuery } from "@/utils/author";
import { AuthorSportListT } from "@/types/authors";
import { getAuthors } from "../api";

const useAuthorList = (
  sportName = "All",
  sportList: AuthorSportListT[] = []
) => {
  const [selectedSport, setSport] = React.useState<string>(sportName);
  const { data, isLoading, isError, isFetching } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: ["author-articles", Type.Article, selectedSport],
    queryFn: () =>
      getAuthors({
        contentType: Type.Article,
        sportName: selectedSport === "All" ? null : selectedSport,
        sport:
          selectedSport === "All"
            ? null
            : sportList.find(({ name }) => selectedSport === name)
                ?.contentSport,
      }),
    select: (response: GetAuthorsQuery) =>
      getArticlesFromAuthorsQuery({ response, selectedSport }),
  });

  return {
    data,
    isError,
    isFetching,
    isLoading,
    selectedSport,
    setSport,
  };
};

export default useAuthorList;
