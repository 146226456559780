import "twin.macro";
import ArticleCard from "@/components/ArticleCard";
import Divider from "@/components/Divider";
import SpotlightArticle from "@/components/SpotlightArticle";
import { AuthoredContentItem } from "@/types/common";

type Template3ColumnProps = {
  col1Article: AuthoredContentItem;
  col2Articles: AuthoredContentItem[];
  col3Articles: AuthoredContentItem[];
};

const Template3Column = ({
  col1Article,
  col2Articles = [],
  col3Articles = [],
}: Template3ColumnProps) => {
  const {
    title,
    description,
    slug,
    modifiedDate,
    thumbnailImageUrl,
    author,
    sport: { name: sportName, slug: sportSlug },
  } = col1Article;

  return (
    <div tw="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
      <SpotlightArticle
        author={author}
        fullwidthMobileImage={false}
        image={thumbnailImageUrl?.url}
        link={`/${slug}`}
        origin="author_list"
        publishedOn={modifiedDate}
        snippet={description}
        sportName={sportName}
        sportSlug={sportSlug}
        tagType="grey"
        title={title}
      />
      <div tw="col-span-1 grid gap-0 xl:col-span-2 xl:grid-cols-2 xl:gap-4">
        <div tw="col-span-1 grid">
          {col2Articles.map((article, idx) => (
            <div key={article.id}>
              <ArticleCard
                author={article.author}
                image={article.thumbnailImageUrl?.url}
                link={`/${article.slug}`}
                origin="author_list"
                publishedOn={article.modifiedDate}
                sportName={article.sport.name}
                sportSlug={article.sport.slug}
                title={article.title}
                displayHorizontal
              />
              {idx < col2Articles.length - 1 && <Divider color="light" />}
            </div>
          ))}
        </div>
        <div tw="xl:hidden">
          <Divider color="light" />
        </div>
        <div tw="col-span-1 grid">
          {col3Articles.map((article, idx) => (
            <div key={article.id}>
              <ArticleCard
                author={article.author}
                image={article.thumbnailImageUrl?.url}
                link={`/${article.slug}`}
                origin="author_list"
                publishedOn={article.modifiedDate}
                sportName={article.sport.name}
                sportSlug={article.sport.slug}
                title={article.title}
                displayHorizontal
              />
              {idx < col3Articles.length - 1 && <Divider color="light" />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Template3Column;
